var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-card-action"},[(_vm.triggerAction)?[(_vm.triggerAction.type === _vm.UserActivationActionType.Message)?[_c('div',{staticClass:"trigger-card-action__message-preview cursor-pointer hover:opacity-75 mt-2",on:{"click":_vm.handleOpenToEdit}},[_c('a-icon',{staticClass:"mr-2",attrs:{"type":"message"}}),(_vm.triggerAction.variants.length && _vm.triggerAction.variants[0].text.trim())?_c('span',{domProps:{"innerHTML":_vm._s(_vm.triggerAction.variants[0].text)}}):_c('span',{staticClass:"font-medium text-danger opacity-75"},[_vm._v(" "+_vm._s(_vm.$t('message_is_not_configured'))+" ")])],1)]:_vm._e(),(_vm.triggerAction.type === _vm.UserActivationActionType.Flow)?[_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.triggerAction,
            'key': 'flow',
            'options': _vm.resolveFlowSelectOption,
            'clearable': false,
          }
        }}})]:_vm._e()]:_vm._e(),_c('a-modal',{staticClass:"trigger-card-action__action-modal",on:{"ok":_vm.onOkClick,"afterClose":_vm.onCancelClick},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.actionMessage)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'variants',
          'hasMedia': true,
          'targetToUpload': {
            'target': 'target',
            'target_id': _vm.$store.state.boardsState.activeBoard.board
          },
          'base-api-url': 'api.presscode.info/cabinet',
        }
      }}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }