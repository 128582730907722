var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-subscription"},[_c('page-title',{attrs:{"translation-params":[_vm.$store.state.boardsState.activeBoard.title]}},[_c('save-channel-config',{attrs:{"slot":"custom-button","on-click":_vm.saveChannelConfig},slot:"custom-button"})],1),_c('a-alert',{attrs:{"show-icon":""}},[_c('extended-help-message',{attrs:{"slot":"message","show-icon":"","items-title":null,"message":_vm.$t('channel_subscription_page_extended_help_message', [_vm.$store.state.channelsState.activeChannel.title]),"footer-item-buttons":"","items":{
        'field_channel_title': _vm.$t('field_channel_extended_help_message', [_vm.$store.getters.activeBoard.title]),
        'field_comments_title': _vm.$t('field_comments_extended_help_message', [_vm.$store.getters.activeBoard.title]),
        'field_content_title': _vm.$t('field_content_extended_help_message',  [_vm.$store.getters.activeBoard.title]),
        'field_sponsorship_title': _vm.$t('field_sponsorship_extended_help_message',  [_vm.$store.getters.activeBoard.title]),
      }},slot:"message"})],1),_c('a-card',{staticClass:"mt-5 mb-2"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'subscriptionType',
          'options': _vm.subscriptionTypeOptions,
          'clearable': false,
          'hasAnchor': true
        }
      }}}),(_vm.subscriptionType !== _vm.LimitedSubscriptionType.None)?[_c('a-alert',{attrs:{"show-icon":"","message":_vm.subscriptionTypeAlertMessage}})]:_vm._e()],2),(_vm.subscriptionType !== _vm.LimitedSubscriptionType.None)?[_c('activation-action-data',{staticClass:"mt-5",attrs:{"disabled":_vm.subscriptionType === 'None',"board":_vm.$store.state.boardsState.activeBoard,"flow-options":_vm.resolveFlowSelectOption,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"buttons":_vm.newPmButtons,"get-flow-preview":function (key) { return ("https://api.presscode.info/thumbnail/flow/" + key); },"placeholders":_vm.botFeedbackPlaceholders,"model-path":"issue"},model:{value:(_vm.subscriptionConfig),callback:function ($$v) {_vm.subscriptionConfig=$$v},expression:"subscriptionConfig"}}),_c('activation-action-data',{staticClass:"mt-5",attrs:{"disabled":_vm.subscriptionType === 'None',"board":_vm.$store.state.boardsState.activeBoard,"flow-options":_vm.resolveFlowSelectOption,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"buttons":_vm.newPmButtons,"get-flow-preview":function (key) { return ("https://api.presscode.info/thumbnail/flow/" + key); },"placeholders":_vm.botFeedbackPlaceholders,"model-path":"goodbye"},model:{value:(_vm.subscriptionConfig),callback:function ($$v) {_vm.subscriptionConfig=$$v},expression:"subscriptionConfig"}}),(_vm.subscriptionType === _vm.LimitedSubscriptionType.Comments)?_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('comments_subscription_warning_section_title')}},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('comments_subscription_warning_section_alert_message')}}),(_vm.subscriptionConfig && _vm.subscriptionConfig.comments_subscription_warning !== undefined)?_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.messageEditorToPlainSetter,
          'args': {
            'hasMedia': true,
            'model': _vm.subscriptionConfig,
            'key': 'comments_subscription_warning',
            'availableButtonsTypes': _vm.getNewDefaultButtons(),
            'base-api-url': 'api.presscode.info/cabinet',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.activeBoard.board
            },
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'placeholders': _vm.botFeedbackPlaceholders,
            'hasAnchor': true,
          }
        }}}):_vm._e()],1):_vm._e(),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('subscription_notify_section_title')}},[_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":"","message":_vm.$t('subscription_notify_section_alert_message')}}),_c('config-field',{attrs:{"has-anchor":"","title":_vm.$t('notify_time'),"mini-help-message":_vm.$t('notify_time_mini_help_message'),"field-key":"channel_notify_time"}},[_c('div',{staticClass:"flex flex-col xs:flex-row xs:gap-5"},[_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.subscriptionConfig,
                'key': 'from',
                'time24hr': true,
                'clearable': true,
                'disabled': _vm.subscriptionType === 'None',
              },
            }}}),_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.subscriptionConfig,
                'key': 'to',
                'time24hr': true,
                'clearable': true,
                'disabled': _vm.subscriptionType === 'None',
              },
            }}})],1)]),_c('a-divider'),_c('config-field',{attrs:{"has-anchor":"","title":_vm.$t('field_subscription_notify_title'),"mini-help-message":_vm.$t('field_subscription_notify_mini_help_message'),"field-key":"channel_subscription_notify"}},[_c('a-button',{attrs:{"slot":"titleButton","type":"primary","icon":"plus"},on:{"click":_vm.addRawNotify},slot:"titleButton"}),(_vm.frontendNotifies.length === 0)?_c('empty-data'):_c('div',{staticClass:"mt-2"},_vm._l((_vm.frontendNotifies),function(item,index){return _c('a-card',{key:item.guid,ref:("trigger-" + (item.guid)),refInFor:true,staticClass:"notify-card mb-2"},[_c('a-row',{attrs:{"type":"flex","gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
                    'func': _vm.modelSetter,
                    'args': {
                      'model': item,
                      'key': 'time',
                      'units': [_vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week, _vm.StepUnit.Month],
                      'prefix': 'subscription_notify_',
                      'disabled': _vm.subscriptionType === 'None',
                    }
                  }}})],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('type-select',{attrs:{"active-item":item.action.type,"dropdown-options":_vm.actionOptions},on:{"item-click":function (newType) { return _vm.onActionItemClick(item, newType); },"setting-click":function($event){_vm.actionModalOpen = true}}}),_c('trigger-card-action',{attrs:{"action-modal-open":_vm.actionModalOpen,"action-type":item.action.type},on:{"update:actionModalOpen":function($event){_vm.actionModalOpen=$event},"update:action-modal-open":function($event){_vm.actionModalOpen=$event},"action-change":function (data) { return item.action = data; }},model:{value:(item.action),callback:function ($$v) {_vm.$set(item, "action", $$v)},expression:"item.action"}})],1)],1),_c('a-icon',{attrs:{"slot":"actions","type":"delete"},on:{"click":function($event){return _vm.handleRemoveItem(item)}},slot:"actions"})],1)}),1),(_vm.frontendNotifies.length > 2)?_c('add-entity-card',{staticClass:"w-full",staticStyle:{"min-height":"48px"},on:{"click":_vm.addRawNotify}}):_vm._e()],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }